import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ContactForm from "components/ContactForm/ContactForm.jsx";

import contactMeStyle from "assets/jss/material-kit-react/views/contactPageSections/contactMeStyle.jsx";

class ContactMeSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem cs={12} sm={12} md={8}>
            <h2 className={classes.title}>Contact me</h2>
            <ContactForm formName={"contact-form"}/>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(contactMeStyle)(ContactMeSection);
