import React from "react";
import Helmet from "react-helmet";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// React icons
import { FaUndo } from 'react-icons/fa';

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

// Sections for this page
import ContactSection from "./Sections/ContactSection.jsx";

//styles
import errorPageStyle from "assets/jss/material-kit-react/views/errorPage.jsx";

class ErrorPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Helmet title={"Jason M. Casebier"} />
        <Header
          color="transparent"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 185,
            color: "white"
          }}
          {...rest}
        />
        <Parallax filter image={require("assets/img/landing-bg.jpg")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={8}>
                <h1 className={classes.title}>Oops....</h1>
                <h4>
                The page you are looking for appears to have been lost in space. Fortunately, there is an emergency teleportation button below.
                </h4>
                <br />
                <Button
                  color="primary"
                  size="lg"
                  href="/"
                  rel="noopener noreferrer"
                >
                  <FaUndo/>
                  Back to safety
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <ContactSection />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(errorPageStyle)(ErrorPage);
