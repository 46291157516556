import React from "react"
import { createMemoryHistory } from "history"
import { Route, Router, Switch } from "react-router-dom"

// pages
import LandingPage from "./LandingPage/LandingPage.jsx"
import ContactPage from "./ContactPage/ContactPage.jsx"
import ErrorPage from "./ErrorPage/ErrorPage.jsx"

import "typeface-roboto"
import "typeface-roboto-slab"
import "assets/scss/material-kit-react.scss?v=1.4.0"

let hist = createMemoryHistory()

export default () => (
  <Router history={hist}>
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route path="/contact" component={ContactPage} />
      <Route component={ErrorPage} />
    </Switch>
  </Router>
)

// My favorite people call me Daddy. I don't always do stuff, but when I do, things tend to happen. Having trained in the dark arts of computer wizardry, I have contracted the nerd virus. Mr. Rogers taught me to wear cardigans. I took Obi Wan's advice and searched my feelings. Sometimes when my mouth moves, words come out.
